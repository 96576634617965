import React, { useEffect, useState, useContext,useRef } from 'react'
import { IoIosWarning } from 'react-icons/io'
import validator from 'validator'
import { QuestionsContext } from '../questionsProvider'
import useClickButton from '../hooks/useClickButton'
import ReactPixel from 'react-facebook-pixel';

export default function QuestionEight ({ goToNextQuestion,questionNumber }) {
  const [email, setEmail] = useState('')
  const [minCharacterWarning, setMinCharacterWarning] = useState(false)
  const [maxCharacterWarning, setMaxCharacterWarning] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const { questions, updateQuestions } = useContext(QuestionsContext)


  const updateName = e => {
    setEmail(e.target.value)
  }

  const verifyAndUpdate = () => {
    if (!validator.isEmail(email)) {
      return setEmailError(true)
    } else {
      updateQuestions('email', email)
      ReactPixel.track('ViewContent');
    }
  }

  const keyPressOnDocument = e => {
    if (e.key === 'Enter' && questions.email === null && questionNumber === 8) {
      verifyAndUpdate()
    }
  }

  useEffect(() => {
    if (emailError) {
      validator.isEmail(email) && setEmailError(false)
    }
  }, [email, emailError])
  
  useEffect(() => {
    document.addEventListener('keypress', keyPressOnDocument)
    return () => {
      document.removeEventListener('keypress', keyPressOnDocument)
    }
  },[email,questionNumber])

  return (
    <div className='flex flex-col gap-2'>
      {emailError && !validator.isEmail(email) && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> "{email}" is not a valid email address
          </p>
        </div>
      )}
      <p className='text-[#868686] text-[18px]'>
        One of our friendly representatives will contact you shortly. Please
        tell us how we can reach you about your free consultation.
      </p>

      <div className='flex flex-col items-end gap-2 mt-2'>
        <div className='w-full'>
          <span className='text-[18px] text-[#4e5d6c]'>
            8. Email <span className='text-red-700'>*</span>
          </span>
          <input
            value={email}
            onChange={updateName}
            className='bg-white w-full text-[#4b5a69] py-2 rounded-[10px] border-2 border-[#bfc4ca] pl-3 focus:border-[#3a8ea8] focus:border-4 focus:outline-none'
          />
        </div>
        <div>
          <button
            onClick={verifyAndUpdate}
            className='bg-[#ff670f] text-white font-bold px-8 py-2 w-fit rounded-md'
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
