import React, { useContext } from 'react'
import { QuestionsContext } from '../questionsProvider'
import ReactPixel from 'react-facebook-pixel';

export default function QuestionOne ({ goToNextQuestion }) {
  const { questions, updateQuestions } = useContext(QuestionsContext)
  const saveAnswer = injured => {
    const isInjured = injured ? 'Yes' : 'No'
    updateQuestions('questionOne', isInjured)
  }
  return (
    <>
      <div className='flex flex-col gap-2'>
        <p className='text-[#868686] text-[18px]'>Question 1 out 6</p>
        <span className='text-[18px] text-[#566573]'>
          1. How bad was your car accident?{' '}
          <span className='text-red-700'>*</span>
        </span>
        <div
          className='flex flex-col gap-4 mt-2'
          style={{ padding: '.3em 1em 1em 1.5em' }}
        >
          <button
            onClick={() => {
              saveAnswer(true);
              ReactPixel.track('ViewContent')
            }}
            className='bg-[#3a8ea8] text-white p-[20px] rounded-[10px]'
          >
            I was injured
          </button>
          <button
            onClick={() => saveAnswer(false)}
            className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
            style={{ border: '1px solid #4b5a69' }}
          >
            I was not injured at all
          </button>
        </div>
      </div>
    </>
  )
}
