import React, { createContext, useContext, useState } from 'react'

export const QuestionsContext = createContext()

export const QuestionsProvider = ({ children }) => {
  const [questions, setQuestions] = useState({
    questionOne: null,
    questionTwo: null,
    questionThree: null,
    questionFour: null,
    questionFive: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    zip_code: null
  })
  const updateQuestions = (question, value) => {
    setQuestions({ ...questions, [question]: value })
  }
  return (
    <QuestionsContext.Provider value={{ questions, updateQuestions }}>
      {children}
    </QuestionsContext.Provider>
  )
}
