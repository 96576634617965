import React, { useContext } from 'react'
import { QuestionsContext } from '../questionsProvider'
import ReactPixel from 'react-facebook-pixel';

export default function QuestionTwo ({ goToNextQuestion }) {
  const { questions, updateQuestions } = useContext(QuestionsContext)

  const saveAnswer = lawyer => {
    const isLawyer = lawyer
      ? 'Yes, I already have a lawyer/received compensation'
      : 'No'
    updateQuestions('questionTwo', isLawyer)
  }
  return (
    <div className='flex flex-col gap-2'>
      <p className='text-[#868686] text-[18px]'>Question 2 out 6</p>
      <span className='text-[18px] text-[#566573]'>
        2. Are you currently represented by a lawyer for the accident?{' '}
        <span className='text-red-700'>*</span>
      </span>
      <div
        className='flex flex-col gap-4 mt-2'
        style={{ padding: '.3em 1em 1em 1.5em' }}
      >
        <button
          onClick={() => {
            saveAnswer(false);
            ReactPixel.track('ViewContent')
          }}
          className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
          style={{ border: '1px solid #4b5a69' }}
        >
          No
        </button>
        <button
          onClick={() => saveAnswer(true)}
          className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
          style={{ border: '1px solid #4b5a69' }}
        >
          Yes, I already have a lawyer/received compensation
        </button>
      </div>
    </div>
  )
}
