import React, { useEffect, useRef, useState, useContext } from 'react'
import QuestionOne from './components/questionOne'
import QuestionTwo from './components/questionTwo'
import QuestionThree from './components/questionThree'
import QuestionFour from './components/questionFour'
import QuestionFive from './components/questionFive'
import QuestionSix from './components/questionSix'
import QuestionSeven from './components/questionSeven'
import QuestionEight from './components/questionEight'
import QuestionNine from './components/questionNine'
import QuestionZipCode from './components/QuestionZipCode'
import { IoCheckmarkCircle } from 'react-icons/io5'
import LeadIdToken from './components/leadToken'
import './App.css'
import { QuestionsContext } from './questionsProvider'
import ReactPixel from 'react-facebook-pixel';
import Hotjar from '@hotjar/browser';
import axios from 'axios'
import Cookies from 'js-cookie';


function App () {
  let fbPixelId;
  const searchParams = new URLSearchParams(window.location.search);
  const [userIP, setUserIP] = useState('');

  const fbclidFromCookie = Cookies.get('_fbc');
  const fbp = Cookies.get('_fbp') || 'fb.1.1.1';
  const epoch = Math.floor(new Date().getTime() / 1000);
  
  let generatedFbclid = false;
  if(searchParams.get("fbclid")){
    generatedFbclid = `fb.1.${epoch}.${searchParams.get("fbclid")}`
  }
  const fbc = fbclidFromCookie || generatedFbclid || 'fb.1.1.1';

  if(searchParams.has("pixel")){
    console.log("Received pixel")
    fbPixelId = searchParams.get("pixel");
  }

  useEffect(() => {
    ReactPixel.init(fbPixelId);
    ReactPixel.pageView();
    console.log("FB Pixel ID: " + fbPixelId);

    const siteId = 3867745;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);


    console.log(`User Agent: ${userAgent}`)
  
  }, []);

  useEffect(() => {

    const fetchIPAddress = async () => {
      try {
        const response = await axios.get('https://ipinfo.io/ip');
        setUserIP(response.data);
        console.log(`User IP: ${response.data}`)
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIPAddress();
  }, []);

  const userAgent = navigator.userAgent;

  const [questionNumber, setQuestionNumber] = useState(1)
  const [progress, setProgress] = useState(0)
  const [timer, setTimer] = useState(120)
  const [runTimer, setRunTimer] = useState(false)
  const [showTimerMessage, setShowTimerMessage] = useState(false)
  const leadRef = useRef(null)
  const { questions, updateQuestions } = useContext(QuestionsContext)

  const [animationEnd, setAnimationEnd] = useState({
    questionOne: false,
    questionTwo: false,
    questionThree: false,
    questionFour: false,
    questionFive: false,
    questionSix: false,
    questionSeven: false,
    questionEight: false,
    questionNine: false,
    questionTen: false
  })
  const [whoIsActive, setWhoIsActive] = useState({
    1: true,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false
  })
  const [trustedFormValue, setTrustedFormValue] = useState('')
  const trustedFormRef = useRef(null)
  const isLastQuestion = useRef(false)

  const startTimer = () => setTimer(true)
  const stopTimer = () => setTimer(false)
  const goToNextQuestion = async () => {
    console.log(questions)
    return setQuestionNumber(prev => prev + 1)
  }

  const fireAnimationEnd = question => {
    setAnimationEnd(prev => {
      return { ...prev, [question]: true }
    })
  }

  const transformSecondsToMinutes = seconds => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0')
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0')
    return `${minutes}:${remainingSeconds}`
  }

  useEffect(() => {
    setProgress((questionNumber / 11) * 100)
  }, [questionNumber])

  useEffect(() => {
    if (runTimer) {
      const intervalId = setInterval(() => {
        setTimer(prev => prev - 1)
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [runTimer])

  const lastQuestion = async () => {
    const dataToSend = {
      first_name: questions.first_name,
      last_name: questions.last_name,
      email: questions.email,
      phone_number: questions.phone,
      question_1: questions.questionOne,
      question_2: questions.questionTwo,
      question_3: questions.questionThree,
      question_4: questions.questionFour,
      question_5: questions.questionFive,
      leadId: leadRef.current.value,
      zip_code: questions.zip_code,
      trusted_form_cert_url: trustedFormValue
    }

    const dataToSendLeadProsper = {
      "lp_campaign_id":"20184",
      "lp_supplier_id":"47729",
      "lp_key":"eyo0iq0qjskvq0",
      "first_name": questions.first_name,
      "last_name": questions.last_name,
      "email": questions.email,
      "phone": questions.phone,
      "ip_address": "159.203.134.3",
      "landing_page_url":"https://mva.findmeaid.com",
      "jornaya_leadid":leadRef.current.value,
      "trustedform_cert_url": trustedFormValue,
      "incident_date": questions.questionFive,
      "has_attorney": questions.questionTwo,
      "comments": questions.questionFour,
      "zip_code": questions.zip_code,
      "userIp": userIP,
      "userAgent": userAgent,
      "fbp":fbp,
      "fbc": fbc,
      "injured":questions.questionOne,
      "at_fault":questions.questionThree
    }


    const res = await fetch('https://seal-app-nf5ne.ondigitalocean.app/mva', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)
    })

    const resLeadProsper = await fetch('https://api.leadprosper.io/ingest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSendLeadProsper)
    })

    if (resLeadProsper) {
      console.log(resLeadProsper)
      goToNextQuestion()
    } else {
      console.log(dataToSend)
      console.log(dataToSendLeadProsper)
      alert('Something went wrong, please try again later')
    }
  }

  useEffect(() => {
    const intervalId =
      !trustedFormValue &&
      setInterval(() => {
        const inputElement = document.getElementsByName(
          'xxTrustedFormCertUrl'
        )[0]
        if (inputElement) {
          setTrustedFormValue(inputElement.value)
          clearInterval(intervalId)
        }
      }, 1000)
    return () => clearInterval(intervalId)
  }, [trustedFormValue])

  useEffect(() => {
    if (!questions.questionOne) return
    if (isLastQuestion.current) {
      lastQuestion()
      return
    }
    goToNextQuestion()
  }, [questions])

  useEffect(() => {
    if (questionNumber === 10) {
      isLastQuestion.current = true
    }
  }, [questionNumber])


  if (questionNumber >= 11) {
    return (
      <div className='relative w-screen h-screen bg-white flex items-center justify-center text-center'>
        <div className='source-sans mb-40' style={{ maxWidth: 500 }}>
          <h1
            className='text-[#3170b0] font-bold text-[40px] mb-3'
            style={{ lineHeight: '48px' }}
          >
            Our Attorneys Are Reviewing Your Case. Be Prepared To Answer Your
            Phone.
          </h1>
          <p
            className='text-[22px] text-[#666666] font-bold mb-4'
            style={{ lineHeight: '26.4px' }}
          >
            If you were not at fault, not represented, and within the statute of
            limitations we are going to call you as soon as possible. Please be
            ready to pick up your phone.
          </p>
          <IoCheckmarkCircle className='text-[#3070b0] text-[150px] mx-auto' />
        </div>
        <small className='text-[#555555] text-[11px] fixed bottom-8 left-2/4 -translate-x-2/4 -translate-y-2/4'>
          Copyright © 2020 caraccidentcounsel.com
        </small>
      </div>
    )
  }

  return (
    <div>
      <header className='bg-[#2c3e50] w-full min-h-[2vh] p-[10px] relative'>
        <div className='flex items-center gap-3 text-white text-[17px]'>
          <img src='/img/logo.png' alt='logo' className='max-w-[30px]' />
          <h1>Car Accident Counsel</h1>
        </div>
        <span className='text-white absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 font-[400] text-[18px]'>
        </span>
      </header>
      <div>
        <section className='bg-[url("https://surveygizmolibrary.s3.amazonaws.com/library/698034/headerbg.jpg")] pb-24 bg-no-repeat bg-cover bg-fixed'>
          <div className='flex flex-col align-items-center justify-center text-center pt-14 gap-5 text-white'>
            <h2>
              <b className='text-[30px] text-white'>
                Receive{' '}
                <span
                  style={{
                    borderBottom: '3px solid #ff670f',
                    paddingBottom: 2
                  }}
                >
                  Maximum Accident Injury Payout
                </span>{' '}
                with the Help of
                <br /> Trusted Personal Injury Lawyers
              </b>
            </h2>
            <p className='text-[18px]'>
              Take this quick assessment to get a{' '}
              <span className='text-[#ff670f]'>FREE CASE CONSULTATION</span> and
              <span className='text-[#ff670f]'>
                {' '}
                know how much is owed to you
              </span>
            </p>
            {timer <= 0 && (
              <div
                className='text-[#067420] rounded-[7px] w-full max-w-[600px] mx-auto mt-3'
                style={{
                  border: '1px solid #FFFFFF',
                  background:
                    'linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.7) 100%)',
                  backdropFilter: 'blur(3px)',
                  padding: '10px 20px'
                }}
              >
                <p className='p-[15px]'>
                  Finish and submit the form to qualify for our free
                  consultation offer
                </p>
              </div>
            )}
            {questionNumber !== 1 && timer > 0 && (
              <div
                className='text-[#40505f] rounded-[7px] w-full max-w-[600px] mx-auto mt-3 flex'
                style={{
                  border: '1px solid #FFFFFF',
                  background:
                    'linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.7) 100%)',
                  backdropFilter: 'blur(3px)',
                  padding: '10px 20px'
                }}
              >
                <p className='p-[15px] text-right'>
                  Submit within the next 2 minutes to skip the line and get
                  immediate help
                </p>
                <div
                  className='px-5 w-fit h-[60px] font-medium text-white text-4xl flex items-center justify-center my-auto rounded-md'
                  style={{
                    background:
                      'linear-gradient(180deg, #222222 0%, #474747 100%)'
                  }}
                >
                  {transformSecondsToMinutes(timer)}
                </div>
              </div>
            )}
            <div
              className='bg-white mx-auto rounded-[20px] w-full max-w-[800px] min-h-[300px] overflow-hidden'
              style={{ padding: '1.5em 30px 2em' }}
            >
              <div
                style={{
                  pointerEvents: questionNumber === 2 ? 'none' : 'auto'
                }}
                className={`${questionNumber === 1 ? '' : 'outAnimation'} ${
                  animationEnd.questionOne ? 'hidden' : ''
                }`}
                onAnimationEnd={() => {
                  fireAnimationEnd('questionOne')
                  setRunTimer(true)
                }}
              >
                <QuestionOne goToNextQuestion={goToNextQuestion} />
              </div>
              <div
                style={{
                  pointerEvents: questionNumber === 3 ? 'none' : 'auto'
                }}
                className={`${
                  questionNumber === 3
                    ? 'outAnimation'
                    : animationEnd.questionOne
                    ? ''
                    : 'hidden'
                } ${animationEnd.questionTwo ? 'hidden' : ''}`}
                onAnimationEnd={() => fireAnimationEnd('questionTwo')}
              >
                <QuestionTwo goToNextQuestion={goToNextQuestion} />
              </div>
              <div
                style={{
                  pointerEvents: questionNumber === 4 ? 'none' : 'auto'
                }}
                className={`${
                  questionNumber === 4
                    ? 'outAnimation'
                    : animationEnd.questionTwo
                    ? ''
                    : 'hidden'
                } ${animationEnd.questionThree ? 'hidden' : ''}`}
                onAnimationEnd={() => fireAnimationEnd('questionThree')}
              >
                <QuestionThree goToNextQuestion={goToNextQuestion} />
              </div>
              <div
                style={{
                  pointerEvents: questionNumber === 5 ? 'none' : 'auto'
                }}
                className={`${
                  questionNumber === 5
                    ? 'outAnimation'
                    : animationEnd.questionThree
                    ? ''
                    : 'hidden'
                } ${animationEnd.questionFour ? 'hidden' : ''}`}
                onAnimationEnd={() => fireAnimationEnd('questionFour')}
              >
                <QuestionFour goToNextQuestion={goToNextQuestion} />
              </div>
              <div
                style={{
                  pointerEvents: questionNumber === 6 ? 'none' : 'auto'
                }}
                className={`${
                  questionNumber === 6
                    ? 'outAnimation'
                    : animationEnd.questionFour
                    ? ''
                    : 'hidden'
                } ${animationEnd.questionFive ? 'hidden' : ''}`}
                onAnimationEnd={() => fireAnimationEnd('questionFive')}
              >
                <QuestionFive goToNextQuestion={goToNextQuestion} />
              </div>
              {!animationEnd.questionSix && (
                <div
                  style={{
                    pointerEvents: questionNumber === 7 ? 'none' : 'auto'
                  }}
                  className={`${
                    questionNumber === 7
                      ? 'outAnimation'
                      : animationEnd.questionFive
                      ? ''
                      : 'hidden'
                  } ${animationEnd.questionSix ? 'hidden' : ''}`}
                  onAnimationEnd={() => fireAnimationEnd('questionSix')}
                >
                  <QuestionSix
                    goToNextQuestion={goToNextQuestion}
                    questionNumber={questionNumber}
                  />
                </div>
              )}
              {!animationEnd.questionSeven && (
                <div
                  style={{
                    pointerEvents: questionNumber === 8 ? 'none' : 'auto'
                  }}
                  className={`${
                    questionNumber === 8
                      ? 'outAnimation'
                      : animationEnd.questionSix
                      ? ''
                      : 'hidden'
                  } ${animationEnd.questionSeven ? 'hidden' : ''}`}
                  onAnimationEnd={() => fireAnimationEnd('questionSeven')}
                >
                  <QuestionSeven
                    goToNextQuestion={goToNextQuestion}
                    questionNumber={questionNumber}
                  />
                </div>
              )}
              {!animationEnd.questionEight && (
                <div
                  style={{
                    pointerEvents: questionNumber === 9 ? 'none' : 'auto'
                  }}
                  className={`${
                    questionNumber === 9
                      ? 'outAnimation'
                      : animationEnd.questionSeven
                      ? ''
                      : 'hidden'
                  } ${animationEnd.questionEight ? 'hidden' : ''}`}
                  onAnimationEnd={() => fireAnimationEnd('questionEight')}
                >
                  <QuestionEight
                    goToNextQuestion={goToNextQuestion}
                    questionNumber={questionNumber}
                  />
                </div>
              )}
              {!animationEnd.questionNine && (
                <div
                  style={{
                    pointerEvents: questionNumber === 10 ? 'none' : 'auto'
                  }}
                  className={`${
                    questionNumber === 10
                      ? 'outAnimation'
                      : animationEnd.questionEight
                      ? ''
                      : 'hidden'
                  } ${animationEnd.questionNine ? 'hidden' : ''}`}
                  onAnimationEnd={() => fireAnimationEnd('questionNine')}
                >
                  <QuestionZipCode
                    goToNextQuestion={goToNextQuestion}
                    questionNumber={questionNumber}
                  />
                </div>
              )}
              {!animationEnd.questionTen && (
                <div
                  style={{
                    pointerEvents: questionNumber === 11 ? 'none' : 'auto'
                  }}
                  className={`${
                    questionNumber === 11
                      ? 'outAnimation'
                      : animationEnd.questionNine
                      ? ''
                      : 'hidden'
                  } ${animationEnd.questionTen ? 'hidden' : ''}`}
                  onAnimationEnd={() => fireAnimationEnd('questionTen')}
                >
                  <QuestionNine
                    goToNextQuestion={lastQuestion}
                    questionNumber={questionNumber}
                  />
                </div>
              )}
            </div>
            <p className='text-white text-[14px]'>
              <i>FREE No Obligation Consultation Pay Nothing!</i>
            </p>
            <b className='text-white'>
              Our Expert Lawyers Have Been Rated 5-stars
            </b>
            <div className='flex items-center gap-4 justify-center'>
              <div className='max-w-[220px]'>
                <img src='/img/yelp.png' alt='yelp' />
              </div>
              <div className='max-w-[220px]'>
                <img src='/img/google.png' alt='google' />
              </div>
              <div className='max-w-[220px]'>
                <img src='/img/trustpilot.png' alt='trustpilot' />
              </div>
            </div>
          </div>
        </section>
        <section className='bg-white text-[#4a5a69] px-10 pt-10 pb-28'>
          <p
            className='text-center text-[10px] max-w-[1200px] mx-auto'
            style={{ lineHeight: '10.3111px' }}
          >
            {/* By clicking on the "Next" button above, you consent, acknowledge,
            and agree to the following: Our{' '}
            <a
              className='text-[#20b9ed] underline'
              href='https://findmeaid.com/terms/'
            >
              Terms of Service
            </a>
            <br /> and{' '}
            <a
              className='text-[#20b9ed] underline'
              href='https://findmeaid.com/privacy/'
            >
              Privacy Policy
            </a>
            . That you are providing express "written" consent for
            FindMeAid or its{' '}
            <a
              className='text-[#20b9ed] underline'
              href='https://www.caraccidentcounsel.com/network-of-lawyers-and-advocates/'
            >
              network of lawyers and advocates
            </a>{' '}
            to call you (autodialing, text and pre-recorded messaging for
            convenience) via telephone, mobile device (including SMS and MMS -
            charges may apply depending on your carrier, not by us, messaging
            frequency varies; text HELP for help; text STOP to cancel), even if
            your telephone number is currently listed on any internal,
            corporate, state or federal Do-Not-Call list. We take your privacy
            seriously and you may receive electronic communications, including
            periodic emails with important news, financial tips, tools and more.
            You can always unsubscribe at any time. All website interactions and
            phone calls are recorded for compliance and quality assurance
            purposes. Consent is not required as a condition to utilize
             services and you are under no obligation to
            purchase anything. */}
            <br /> This site is not a part of the Facebook website or Facebook
            Inc. Additionally, This site is NOT endorsed by Facebook in any way.
            FACEBOOK is a trademark of FACEBOOK, Inc.
            <br /> This site is not a part of the YouTube website or Google,
            LLC.
            <br /> Additionally, This site is NOT endorsed by Google in any way.
            YouTube is a trademark of Google, LLC.
            {/* <br /> PAID ATTORNEY ADVERTISEMENT: This Web site is a group
            advertisement. It is not a lawyer referral service or prepaid legal
            services plan. This website is not a law firm. The sole basis for
            the inclusion of the participating lawyers or law firms is the
            payment of a fee for exclusive geographical advertising rights. Our
            company does not endorse or recommend any lawyer or law firm who
            participates in the network. It does not make any representation and
            has not made any judgment as to the qualifications, expertise or
            credentials of any participating lawyer. The information contained
            herein is not legal advice. Any information you submit to this
            website may not be protected by attorney-client privilege. All
            photos are of models and do not depict clients. All case evaluations
            are performed by participating attorneys. This website is for
            informational purposes only. By filling out the free consultation
            form, you are NOT forming an attorney-client relationship. You can
            only retain a lawyer by entering into a written retainer agreement
            with an attorney. Our services may not be available in all states. */}
          </p>
          <br/>
          <div className='flex flex-col items-center gap-2'>
            <a
              className='text-[#20b9ed] text-[10px] underline'
              href='https://findmeaid.com/privacy'
            >
              Privacy Policy
            </a>
            <a
              className='text-[#20b9ed]  text-[10px] underline'
              href='https://findmeaid.com/terms'
            >
              Terms of Service
            </a>
            
          </div>
        </section>
        <div className='fixed bg-[#222222] p-[6px] h-[60px] bottom-0 w-full z-50 flex items-center justify-center'>
          <div className='bg-[#bfc5ca] rounded-full w-[160px] h-[23px] mt-3'>
            <div
              className={`bg-[#3a8ea8] h-full text-white relative`}
              style={{
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                borderRight: '1px solid white',
                width:
                  questionNumber === 1 || questionNumber === 2
                    ? '20%'
                    : `${Math.round(progress)}%`
              }}
            >
              <span
                className='absolute right-0 top-2/4 -translate-y-2/4 text-[14px]'
                style={{ right: 1 }}
              >
                {Math.round(progress)}%
              </span>
            </div>
          </div>
        </div>
        <form>

        </form>
        <LeadIdToken leadRef={leadRef} />
      </div>
    </div>
  )
}

export default App
