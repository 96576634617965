import React, { useContext } from 'react'
import { QuestionsContext } from '../questionsProvider'
import ReactPixel from 'react-facebook-pixel';

export default function QuestionFive ({ goToNextQuestion }) {
  const { questions, updateQuestions } = useContext(QuestionsContext)

  const saveAnswer = asnwer => {
    updateQuestions('questionFive', asnwer)
  }
  return (
    <div className='flex flex-col gap-2'>
      <p className='text-[#868686] text-[18px]'>Question 6 out 6</p>
      <span className='text-[18px] text-[#566573]'>
        6. When did the accident happen? <span className='text-red-700'>*</span>
      </span>
      <div
        className='flex flex-col gap-4 mt-2'
        style={{ padding: '.3em 1em 1em 1.5em' }}
      >
        <button
          onClick={() => {
            saveAnswer('10/31/2023')
            ReactPixel.track('ViewContent')
          }}
          className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
          style={{ border: '1px solid #4b5a69' }}
        >
          Within 6 months
        </button>
        <button
          onClick={() => saveAnswer('04/01/2022')}
          className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
          style={{ border: '1px solid #4b5a69' }}
        >
          Within 2 years
        </button>
        <button
          onClick={() => saveAnswer('03/28/2021')}
          className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
          style={{ border: '1px solid #4b5a69' }}
        >
          More than 2 years ago
        </button>
      </div>
    </div>
  )
}
