import React, { useEffect, useState,  useContext,useRef } from 'react'
import { IoIosWarning } from 'react-icons/io'
import validator from 'validator'
import { QuestionsContext } from '../questionsProvider'
import useClickButton from '../hooks/useClickButton'
import ReactPixel from 'react-facebook-pixel'

export default function QuestionNine ({ goToNextQuestion,questionNumber }) {
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [tcpa, setTcpa] = useState(true)
  const { questions, updateQuestions } = useContext(QuestionsContext)
  const buttonRef = useRef(null)
  const buttonClicked = useRef(false)
  const updateName = e => {
    const re = /^[0-9]*$/;
    if (re.test(e.target.value) && e.target.value.length <= 10) {
      setPhone(e.target.value)
    }
  }
  const verifyAndSend = () => {
    if (phone.length > 10 || phone.length < 10) {
      return setPhoneError(true)
    }
    if(!tcpa){
      if(!tcpa) return alert("Please agree to the TCPA disclosure")
    }
    updateQuestions('phone', phone)
    ReactPixel.track('ViewContent')
  }
  const keyPressOnDocument = e => {
    if (e.key === 'Enter' && questions.phone === null && questionNumber === 10) {
      verifyAndSend()
    }
  }

  useEffect(() => {
    if (phoneError) {
      if (phone.length > 10 || phone.length < 10) {
        setPhoneError(true)
      } else {
        setPhoneError(false)
      }
    }
  }, [phone, phoneError])

  useEffect(() => {
    document.addEventListener('keypress', keyPressOnDocument)
    return () => {
      document.removeEventListener('keypress', keyPressOnDocument)
    }
  },[phone,questionNumber])

  return (
    <div className='flex flex-col gap-2'>
      {phoneError && (phone.length >= 21 || phone.length < 10) && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> "{phone}" is not a valid phone number
          </p>
        </div>
      )}
      <p className='text-[#868686] text-[18px]'>
        One of our friendly representatives will contact you shortly. Please
        tell us how we can reach you about your free consultation.
      </p>

      <div className='flex flex-col items-end gap-2 mt-2'>
        <div className='w-full text-center flex flex-col'>
          <span className='text-[18px] text-[#4e5d6c]'>
            8. What is your cell number? <span className='text-red-700'>*</span>
          </span>
          <span className='text-[#4e5d6c] text-[19px] mb-2'>
            For us to call you about compensation please be ready to pick up
          </span>
          <input
            value={phone}
            onChange={updateName}
            className='bg-white w-full text-[#4b5a69] py-2 rounded-[10px] border-2 border-[#bfc4ca] pl-3 focus:border-[#3a8ea8] focus:border-4 focus:outline-none'
          />
          <div className='flex flex-col gap-2 self-center text-gray-600 text-sm'>
            <span>Characters used: {phone.length} (minimum 10).</span>
            <span>Characters used: {phone.length} out of 10.</span>
          </div>
          <div>
            {phone.length > 4 && (
              <label
                style={{
                  maxWidth: 600,
                  textAlign: 'center',
                  color: 'gray',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.6rem',
                  margin: '0 auto',
                  marginTop:"1rem"
                }}
              >
                {' '}
                <small>
                  TCPA Disclosure: By clicking the below "Next" button you give
                  your consent to FindMeAid and Walker Advertising to call you (autodialing, text and pre-recorded messaging for
            convenience) via telephone, mobile device (including SMS and MMS), even if
            your telephone number is currently listed on any internal,
            corporate, state or federal Do-Not-Call list. Consent is not required to
                  purchase goods or services.
                </small>
                {/* <select
                  onChange={(e) => setTcpa(e.target.value === 'yes')}
                  id='leadid_tcpa_disclosure'
                  style={{ width: 'fit-content', margin: '0 auto' }}
                >
                  <option value='yes'>Yes</option>
                  <option value='no'>No</option>
                </select> */}
              </label>
            )}
          </div>
        </div>
        <div>
          <button
          ref={buttonRef}
            onClick={verifyAndSend}
            className='bg-[#ff670f] text-white font-bold px-8 py-2 w-fit rounded-md'
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
