import React, { useEffect, useState, useContext, useRef } from 'react'
import { IoIosWarning } from 'react-icons/io'
import { QuestionsContext } from '../questionsProvider'
import useClickButton from '../hooks/useClickButton'
import ReactPixel from 'react-facebook-pixel';

export default function QuestionSeven ({ goToNextQuestion,questionNumber }) {
  const [lastName, setLastName] = useState('')
  const [minCharacterWarning, setMinCharacterWarning] = useState(false)
  const [maxCharacterWarning, setMaxCharacterWarning] = useState(false)
  const { questions, updateQuestions } = useContext(QuestionsContext)


  const updateName = e => {
    const nameOnlyCharacters = e.target.value //.replace(/[^a-zA-Z ]/g, '') #was giving errors
    setLastName(nameOnlyCharacters)
  }

  const checkAndUpdate = () => {
    if (lastName.length < 1) {
      return setMinCharacterWarning(true)
    }
    if (lastName.length > 60) {
      return setMaxCharacterWarning(true)
    }
    updateQuestions('last_name', lastName)
    ReactPixel.track('ViewContent')
  }
  const keyPressOnDocument = e => {
    if (e.key === 'Enter' && questions.last_name === null && questionNumber === 7) {
      checkAndUpdate()
    }
  }

  useEffect(() => {
    if (minCharacterWarning) {
      lastName.length > 1 && setMinCharacterWarning(false)
    }
    if (maxCharacterWarning) {
      lastName.length < 60 && setMaxCharacterWarning(false)
    }
  }, [minCharacterWarning, maxCharacterWarning, lastName])


  useEffect(() => {
    document.addEventListener('keypress', keyPressOnDocument)
    return () => {
      document.removeEventListener('keypress', keyPressOnDocument)
    }
  },[lastName,questionNumber])


  return (
    <div className='flex flex-col gap-2'>
      {minCharacterWarning && lastName.length < 1 && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> You have typed fewer characters than required (
            {lastName.length} of 20 characters)
          </p>
        </div>
      )}
      {maxCharacterWarning && lastName.length > 60 && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> You have typed more characters than required (
            {lastName.length} of 60 characters)
          </p>
        </div>
      )}
      <p className='text-[#868686] text-[18px]'>
        One of our friendly representatives will contact you shortly. Please
        tell us how we can reach you about your free consultation.
      </p>

      <div className='flex flex-col items-end gap-2 mt-2'>
        <div className='w-full'>
          <span className='text-[18px] text-[#4e5d6c]'>
            8. Last Name <span className='text-red-700'>*</span>
          </span>
          <input
            style={{
              color:
                lastName.length === 0
                  ? 'black'
                  : lastName.length < 1 || lastName.length > 60
                  ? '#ff4747'
                  : 'black'
            }}
            value={lastName}
            onChange={updateName}
            className='bg-white w-full text-[#4b5a69] py-2 rounded-[10px] border-2 border-[#bfc4ca] pl-3 focus:border-[#3a8ea8] focus:border-4 focus:outline-none'
          />
        </div>
        <div className='flex flex-col gap-2 self-center text-gray-600 text-sm'>
          <span>Characters used: {lastName.length} (minimum 1).</span>
          <span>Characters used: {lastName.length} out of 63.</span>
        </div>
        <div>
          <button
            onClick={checkAndUpdate}
            className='bg-[#ff670f] text-white font-bold px-8 py-2 w-fit rounded-md'
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
