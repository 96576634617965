import React, { useEffect, useState, useContext, useRef } from 'react'
import { IoIosWarning } from 'react-icons/io'
import { QuestionsContext } from '../questionsProvider'
import useClickButton from '../hooks/useClickButton'
import ReactPixel from 'react-facebook-pixel';

export default function QuestionSix ({ goToNextQuestion,questionNumber }) {
  const [firstName, setFirstName] = useState('')
  const [minCharacterWarning, setMinCharacterWarning] = useState(false)
  const [maxCharacterWarning, setMaxCharacterWarning] = useState(false)
  const { questions, updateQuestions } = useContext(QuestionsContext)
  const updateName = e => {
    setFirstName( e.target.value)
  }

  const updateFirstName = () => {
    if (firstName.length < 1) {
      return setMinCharacterWarning(true)
    }
    if (firstName.length > 60) {
      return setMaxCharacterWarning(true)
    }
    updateQuestions('first_name', firstName)
    ReactPixel.track('ViewContent')
  }

  const keyPressOnDocument = e => {
    if (e.key === 'Enter' && questions.first_name === null && questionNumber === 6) {
      updateFirstName()
    }
  }

  useEffect(() => {
    if (minCharacterWarning) {
      firstName.length > 1 && setMinCharacterWarning(false)
    }
    if (maxCharacterWarning) {
      firstName.length < 60 && setMaxCharacterWarning(false)
    }
  }, [minCharacterWarning, maxCharacterWarning, firstName])

  useEffect(() => {
    document.addEventListener('keypress', keyPressOnDocument)
    return () => {
      document.removeEventListener('keypress', keyPressOnDocument)
    }
  }, [firstName,questionNumber])

  return (
    <div className='flex flex-col gap-2'>
      {minCharacterWarning && firstName.length < 1 && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> You have typed fewer characters than required (
            {firstName.length} of 20 characters)
          </p>
        </div>
      )}
      {maxCharacterWarning && firstName.length > 60 && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> You have typed more characters than required (
            {firstName.length} of 60 characters)
          </p>
        </div>
      )}
      <p className='text-[#868686] text-[18px]'>
        One of our friendly representatives will contact you shortly. Please
        tell us how we can reach you about your free consultation.
      </p>

      <div className='flex flex-col items-end gap-2 mt-2'>
        <div className='w-full'>
          <span className='text-[18px] text-[#4e5d6c]'>
            7. First Name <span className='text-red-700'>*</span>
          </span>
          <input
            style={{
              color:
                firstName.length === 0
                  ? 'black'
                  : firstName.length < 1 || firstName.length > 60
                  ? '#ff4747'
                  : '#000000'
            }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className='bg-white w-full text-[#4b5a69] py-2 rounded-[10px] border-2 border-[#bfc4ca] pl-3 focus:border-[#3a8ea8] focus:border-4 focus:outline-none'
          />
        </div>
        <div className='flex flex-col gap-2 self-center text-gray-600 text-sm'>
          <span>Characters used: {firstName.length} (minimum 1).</span>
          <span>Characters used: {firstName.length} out of 60.</span>
        </div>
        <div>
          <button
            onClick={updateFirstName}
            className='bg-[#ff670f] text-white font-bold px-8 py-2 w-fit rounded-md'
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
