import React, { useState,useContext } from 'react'
import { IoIosWarning } from 'react-icons/io'
import { QuestionsContext } from '../questionsProvider'
import ReactPixel from 'react-facebook-pixel';

export default function QuestionFour ({ goToNextQuestion }) {
  const [numberCharacters, setNumerCharacters] = useState(0)
  const [minCharacterWarning, setMinCharacterWarning] = useState(false)
  const [maxCharacterWarning, setMaxCharacterWarning] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState('')
  const { questions, updateQuestions } = useContext(QuestionsContext)

  const updateNumberCharacters = e => {
    setNumerCharacters(e.target.value.length)
    setTextAreaValue(e.target.value)
  }

  return (
    <div className='flex flex-col gap-2'>
      {minCharacterWarning && textAreaValue.length < 5 && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> You have typed fewer characters than required (
            {numberCharacters} of 5 characters)
          </p>
        </div>
      )}
      {maxCharacterWarning && textAreaValue.length > 500 && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> You have typed more characters than required (
            {numberCharacters} of 500 characters)
          </p>
        </div>
      )}
      <p className='text-[#868686] text-[18px]'>Question 5 out 6</p>
      <span className='text-[18px] text-[#566573]'>
        5. Tell us about your accident? <span className='text-red-700'>*</span>
      </span>
      <div
        className='flex flex-col gap-4 mt-2 relative items-end'
        style={{ padding: '.3em 1em 1em 1.5em' }}
      >
        <textarea
          onChange={updateNumberCharacters}
          className='border-2 min-h-[200px] w-full text-black'
          style={{
            color:
              textAreaValue.length === 0
                ? 'black'
                : textAreaValue.length < 5 || textAreaValue.length > 500
                ? '#ff4747'
                : '#000000'
          }}
          value={textAreaValue}
        ></textarea>
        <div className='flex flex-col gap-2 self-center text-gray-600 text-sm'>
          <span>Characters used: {numberCharacters} (minimum 5).</span>
          <span>Characters used: {numberCharacters} out of 500.</span>
        </div>
        <button
          onClick={() => {
            if (numberCharacters < 5) {
              setMinCharacterWarning(true)
              return;
            }
            if (numberCharacters > 500) {
              return setMaxCharacterWarning(true)
            }
            updateQuestions('questionFour', textAreaValue)
            ReactPixel.track('ViewContent');
          }}
          className='bg-[#ff670f] text-white font-bold px-8 py-2 w-fit rounded-md'
        >
          Next
        </button>
      </div>
    </div>
  )
}
