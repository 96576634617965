import React, { useContext } from 'react'
import { QuestionsContext } from '../questionsProvider'
import ReactPixel from 'react-facebook-pixel'

export default function QuestionThree ({ goToNextQuestion }) {
  const { questions, updateQuestions } = useContext(QuestionsContext)
  const saveAnswer = fault => {
    const isFault = fault
    updateQuestions('questionThree', isFault)
  }
  return (
    <div className='flex flex-col gap-2'>
      <p className='text-[#868686] text-[18px]'>Question 3 out 6</p>
      <span className='text-[18px] text-[#566573]'>
        3. Are you at fault for the accident? Be honest?{' '}
        <span className='text-red-700'>*</span>
      </span>
      <div
        className='flex flex-col gap-4 mt-2'
        style={{ padding: '.3em 1em 1em 1.5em' }}
      >
        <button
        onClick={() => {
          saveAnswer('No');
          ReactPixel.track('ViewContent');
        }}
          className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
          style={{ border: '1px solid #4b5a69' }}
        >
          No, I am not at fault. The other driver owes damages.
        </button>
        <button
        onClick={() => saveAnswer('Yes.')}
          className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
          style={{ border: '1px solid #4b5a69' }}
        >
          Yes, it was my fault.
        </button>
        <button
        onClick={() => saveAnswer('Other')}
          className='bg-white text-[#4b5a69] p-[20px] rounded-[10px]'
          style={{ border: '1px solid #4b5a69' }}
        >
          There was no one else there. I hit a tree/ditch/animal or mechanical problem.
        </button>
      </div>
    </div>
  )
}
