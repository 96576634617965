import React, { useEffect, useState, useContext, useRef } from 'react'
import { IoIosWarning } from 'react-icons/io'
import validator from 'validator'
import { QuestionsContext } from '../questionsProvider'
import useClickButton from '../hooks/useClickButton'

export default function QuestionZipCode ({ goToNextQuestion,questionNumber }) {
  const [zipCode, setZipCode] = useState('')
  const [zipCodeError, setZipCodeError] = useState(false)
  const { questions, updateQuestions } = useContext(QuestionsContext)
  const buttonRef = useRef(null)
  const buttonClicked = useRef(false)
  const updateName = e => {
    const zipCodeOnlyNumbers = e.target.value.replace(/\D/g, '')
    if (zipCodeOnlyNumbers.length > 5) return
    setZipCode(zipCodeOnlyNumbers)
  }
  const updateAndSend = () => {
    if (!validator.isPostalCode(zipCode, 'US')) {
      return setZipCodeError(true)
    }
    updateQuestions('zip_code', zipCode)
  }
  const keyPressOnDocument = e => {
    if (e.key === 'Enter' && questions.zip_code === null && questionNumber === 9) {
      updateAndSend()
    }
  }

  useEffect(() => {
    if (setZipCode) {
      validator.isPostalCode(zipCode, 'US') && setZipCodeError(false)
    }
  }, [zipCode, zipCodeError])
  useEffect(() => {
    document.addEventListener('keypress', keyPressOnDocument)
    return () => {
      document.removeEventListener('keypress', keyPressOnDocument)
    }
  }, [zipCode,questionNumber])
  return (
    <div className='flex flex-col gap-2'>
      {zipCodeError && !validator.isPostalCode(zipCode, 'US') && (
        <div
          className='bg-[#fff2f4] text-xs text-black text-left py-2'
          style={{ borderLeftWidth: 5, borderLeftColor: '#ff4747' }}
        >
          <p className='flex items-center gap-1 ml-2'>
            <IoIosWarning /> "{zipCode}" is not a valid zip code
          </p>
        </div>
      )}
      <p className='text-[#868686] text-[18px]'>
        One of our friendly representatives will contact you shortly. Please
        tell us how we can reach you about your free consultation.
      </p>

      <div className='flex flex-col items-end gap-2 mt-2'>
        <div className='w-full text-center flex flex-col'>
          <span className='text-[18px] text-[#4e5d6c]'>
            What is your zip code? <span className='text-red-700'>*</span>
          </span>
          <input
            value={zipCode}
            onChange={updateName}
            className='bg-white w-full text-[#4b5a69] py-2 rounded-[10px] border-2 border-[#bfc4ca] pl-3 focus:border-[#3a8ea8] focus:border-4 focus:outline-none'
          />
          <div className='flex flex-col gap-2 self-center text-gray-600 text-sm'>
            <span>Characters used: {zipCode.length} (minimum 5).</span>
            <span>Characters used: {zipCode.length} out of 5.</span>
          </div>
        </div>
        <div>
          <button
            ref={buttonRef}
            onClick={updateAndSend}
            className='bg-[#ff670f] text-white font-bold px-8 py-2 w-fit rounded-md'
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
